import React, { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import Stack from 'components/Stack/Stack'
import BaseCard from 'components/BaseCard/BaseCard'
import CardContent from 'components/BaseCard/CardContent'
import CardKicker from 'components/BaseCard/CardKicker'
import CardTitle from 'components/BaseCard/CardTitle'
import CardDescription from 'components/BaseCard/CardDescription'
import CardMedia from 'components/BaseCard/CardMedia'
import CardCTA from 'components/BaseCard/CardCta'
import { CARD_IMAGE_WIDTH, CARD_IMAGE_HEIGHT } from 'components/BaseCard/lib'
import { Card as CardType } from 'lib/types/card'
import TrackBox from 'components/Track/TrackBox'

export default function Card({
  id,
  image = {
    src: '',
    optimise: false,
    prioritise: false,
  },
  kicker,
  title,
  description,
  buttonText,
  cta,
  colour = '',
  minHeight = undefined,
  maxHeight = undefined,
  handleClick = () => null,
  children,
}: PropsWithChildren<CardType>) {
  return (
    <TrackBox id={`card-${id}`} transparent={true}>
      <BaseCard
        data-test-id={`card-${id}`}
        cta={cta}
        colour={colour}
        minHeight={minHeight}
        maxHeight={maxHeight}
        cardTabbable={buttonText === undefined}
        handleClick={handleClick}
      >
        <Box display="flex" flexDirection="column" flex={1}>
          {image.src && (
            <CardMedia
              data-test-id={`img-${kicker}`}
              optimiseImage={image?.optimise}
              prioritiseImage={image?.prioritise}
              src={image?.src}
              alt={title}
              width={CARD_IMAGE_WIDTH}
              height={CARD_IMAGE_HEIGHT}
            />
          )}
          <Box display="flex" flexDirection="column" flex={1}>
            <CardContent data-test-id={`cardcontent-${kicker}`}>
              <Stack spacing={1}>
                {[
                  kicker && (
                    <CardKicker
                      key="kicker"
                      text={kicker}
                      data-test-id={`kicker-${kicker}`}
                      colour={colour}
                    />
                  ),
                  title && (
                    <CardTitle
                      key="title"
                      data-test-id={`title-${kicker}`}
                      text={title}
                      colour={colour}
                    />
                  ),
                  description && !children && (
                    <CardDescription
                      key="description"
                      data-test-id={`description-${kicker}`}
                      text={description}
                      colour={colour}
                    />
                  ),
                  children && (
                    <React.Fragment key="children">{children}</React.Fragment>
                  ),
                  // The MUI Stack component treats undefined/null as actual values and renders a div for each
                  // Filtering these out here as a workaround
                ].filter(Boolean)}
              </Stack>
            </CardContent>

            {buttonText && (
              <CardCTA
                text={buttonText}
                data-test-id={`cta-${kicker}`}
                colour={colour}
              />
            )}
          </Box>
        </Box>
      </BaseCard>
    </TrackBox>
  )
}
