import { makeStyles } from '@mui/styles'
import { DASH_CARD_MIN_HEIGHT } from 'lib/config'
import { SPACING } from 'lib/theme'
import { ColourScheme } from 'lib/types/card'

type StyleProps = {
  colourScheme?: ColourScheme
  colour?: string
  width?: number | string
  height?: number | string
  minHeight?: number | string
  maxHeight?: number | string
}

const getColourScheme = (colour: string, scheme: ColourScheme) => {
  let defaultColor = '#FFF'

  if (scheme === 'dark') {
    defaultColor = '#161719'
  }

  const currentSchemes = {
    light: {
      card: {
        background: colour || defaultColor,
        kicker: '#FF1C33',
        text: '#2E0029',
      },
      button: {
        background: '#FFFFFF',
        hoverBackground: '#F6F3F6',
        border: '#CCCCCC',
        hoverBorder: '#2E0029',
      },
    },
    dark: {
      card: {
        background: colour || defaultColor,
        kicker: '#FF1C33',
        text: '#F9F9F9',
      },
      button: {
        background: '#161719',
        hoverBackground: '#242629',
        border: '#F9F9F9',
        hoverBorder: '#F9F9F9',
      },
    },
    navy: {
      card: {
        background: colour || defaultColor,
        kicker: '#63d6cb',
        text: '#F9F9F9',
      },
      button: {
        background: '#1F2037',
        hoverBackground: '#292b4a',
        border: '#F9F9F9',
        hoverBorder: '#F9F9F9',
      },
    },
  }

  return currentSchemes[scheme]
}

export default makeStyles(theme => ({
  root: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
    minHeight = DASH_CARD_MIN_HEIGHT,
    maxHeight = undefined,
  }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight,
    ...(maxHeight != null ? { maxHeight } : {}),
    height: '100%',
    backgroundColor: getColourScheme(colour, colourScheme).card.background,
  }),
  rootLink: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
    minHeight = DASH_CARD_MIN_HEIGHT,
    maxHeight = undefined,
  }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight,
    ...(maxHeight != null ? { maxHeight } : {}),
    height: '100%',
    backgroundColor: getColourScheme(colour, colourScheme).card.background,
    '&:hover': {
      boxShadow: '0 0 8px rgba(0,0,0,0.35)',
    },
  }),
  cardLink: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
  }: StyleProps) => ({
    '&:hover': {
      '& button': {
        borderColor: getColourScheme(colour, colourScheme).button.hoverBorder,
        backgroundColor: getColourScheme(colour, colourScheme).button
          .hoverBackground,
      },
    },
  }),
  cardContent: {
    padding: theme.spacing(SPACING.XS),
    width: '100%',
    height: '100%',
  },
  cardActions: {
    padding: `0 ${theme.spacing(SPACING.XS)} ${theme.spacing(SPACING.XS)}`,
  },
  button: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
  }: StyleProps) => ({
    fontSize: '13px',
    height: '30px',
    textTransform: 'uppercase',
    backgroundColor: getColourScheme(colour, colourScheme).button.background,
    borderColor: getColourScheme(colour, colourScheme).button.border,
    color: getColourScheme(colour, colourScheme).card.text,
    '&:hover': {
      borderColor: getColourScheme(colour, colourScheme).button.hoverBorder,
      backgroundColor: getColourScheme(colour, colourScheme).button
        .hoverBackground,
    },
  }),
  kicker: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
  }: StyleProps) => ({
    fontWeight: 'bold',
    padding: SPACING.NONE,
    margin: SPACING.NONE,
    lineHeight: 1,
    textTransform: 'capitalize',
    color: getColourScheme(colour, colourScheme).card.kicker,
  }),
  title: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
  }: StyleProps) => ({
    lineHeight: 1.2,
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    color: getColourScheme(colour, colourScheme).card.text,
  }),
  description: ({
    colourScheme = ColourScheme.Light,
    colour = '#fff',
  }: StyleProps) => ({
    lineHeight: 1.2,
    fontWeight: 'normal',
    fontSize: '14px',
    margin: SPACING.NONE,
    padding: SPACING.NONE,
    color: getColourScheme(colour, colourScheme).card.text,
  }),
}))
