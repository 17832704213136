/* eslint-disable no-magic-numbers */
import { makeStyles } from '@mui/styles'

export type StackSpacing = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export default makeStyles(theme => ({
  root: ({ spacing }: { spacing: StackSpacing }) => ({
    width: `calc(100% + ${theme.spacing(spacing * 0.5)})`,
  }),
}))
