import React from 'react'
import Box from '@mui/material/Box'
import MuiCardContent from '@mui/material/CardContent'
import useStyles from './styles'
import { ICardContent } from 'lib/types/card'

export default function CardContent({
  children,
  'data-test-id': testId,
}: ICardContent) {
  const classes = useStyles({})
  return (
    <Box flex={1}>
      <MuiCardContent data-test-id={testId} className={classes.cardContent}>
        {children}
      </MuiCardContent>
    </Box>
  )
}
