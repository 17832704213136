/* eslint-disable no-magic-numbers */
import React, { ReactNode, Children } from 'react'
import Grid from '@mui/material/Grid'
import useStyles, { StackSpacing } from './styles'
export type { StackSpacing } from './styles'

export type StackDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'
export type StackJustification =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
export type StackItemsAlignment =
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'stretch'
  | 'baseline'
export type StackContentAlignment =
  | 'stretch'
  | 'center'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'
  | 'space-around'

const Stack = ({
  spacing = 0,
  direction = 'column',
  alignItems = 'stretch',
  alignContent = 'flex-start',
  justify = 'flex-start',
  className,
  elementClassName,
  children,
}: {
  spacing?: StackSpacing
  direction?: StackDirection
  alignItems?: StackItemsAlignment
  alignContent?: StackContentAlignment
  justify?: StackJustification
  className?: string
  elementClassName?: string
  children: ReactNode
}) => {
  const classes = useStyles({ spacing })
  return (
    <Grid
      container
      className={className || classes.root}
      wrap="nowrap"
      spacing={spacing}
      direction={direction}
      alignItems={alignItems}
      alignContent={alignContent}
      justifyContent={justify}
    >
      {Children.map(children, (child, i) => (
        <Grid item xs={12} className={elementClassName} key={i}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}

export default Stack
