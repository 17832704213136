import { makeStyles } from '@mui/styles'

type StyleProps = {
  width?: number | string | undefined
  height?: number | string | undefined
}

const useStyles = makeStyles({
  container: ({ width = '100%', height = '100%' }: StyleProps) => ({
    fontSize: 0,
    lineHeight: 0,
    width,
    height,
    position: 'relative',
  }),
  image: {
    height: '100%',
    width: '100%',
  },
})

export default useStyles
