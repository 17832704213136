import React from 'react'
import Typography from '@mui/material/Typography'
import { getClasses } from './BaseCard'
import { ICardDescription } from 'lib/types/card'

export default function CardDescription({
  text,
  'data-test-id': testId,
  colour,
}: ICardDescription) {
  const classes = getClasses(colour)

  return (
    <Typography
      className={classes.description}
      variant="subtitle1"
      data-test-id={testId}
      component="p"
    >
      {text}
    </Typography>
  )
}
