import cx from 'classnames'
import React from 'react'
import Typography from '@mui/material/Typography'
import { getClasses } from './BaseCard'
import { ICardTitle } from 'lib/types/card'
import useGlobalStyles from 'styles/globalStyle'

export default function CardTitle({
  text,
  'data-test-id': testId,
  variant = 'subtitle1',
  colour,
}: ICardTitle) {
  const classes = getClasses(colour)
  const globalClasses = useGlobalStyles()

  return (
    <Typography
      className={cx(classes.title, globalClasses.wordWrap)}
      variant={variant}
      data-test-id={testId}
      component="h2"
    >
      {text}
    </Typography>
  )
}
