import { Box } from '@mui/material'
import React from 'react'
import Image from 'components/Image/Image'
import { ICardMedia } from 'lib/types/card'
import { CARD_IMAGE_HEIGHT, CARD_IMAGE_WIDTH, shimmer, toBase64 } from './lib'

export default function CardMedia({
  'data-test-id': testId,
  optimiseImage = false,
  prioritiseImage = false,
  src,
  alt,
  width,
  height,
}: ICardMedia) {
  return src ? (
    <Image
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(CARD_IMAGE_WIDTH, CARD_IMAGE_HEIGHT)
      )}`}
      width={width}
      height={height}
      data-test-id={testId}
      src={src}
      alt={alt}
      priority={prioritiseImage}
      optimised={optimiseImage}
      placeholder="blur"
      imageStyle={{
        objectFit: 'cover',
      }}
    />
  ) : (
    <Box width={width} height={height} />
  )
}
