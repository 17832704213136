import React from 'react'
import Link from 'next/link'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import { getClasses } from './BaseCard'
import { ICardCTA } from 'lib/types/card'

export default function CardCTA({
  text,
  'data-test-id': testId,
  href,
  colour,
  handleClick = () => null,
}: ICardCTA) {
  const classes = getClasses(colour)

  return (
    <CardActions className={classes.cardActions}>
      {href ? (
        <Link passHref href={href} legacyBehavior>
          <Button
            fullWidth
            className={classes.button}
            variant="outlined"
            data-test-id={testId}
            onClick={handleClick}
          >
            {text}
          </Button>
        </Link>
      ) : (
        <Button
          fullWidth
          className={classes.button}
          variant="outlined"
          data-test-id={testId}
          onClick={handleClick}
        >
          {text}
        </Button>
      )}
    </CardActions>
  )
}
