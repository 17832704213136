import { Box } from '@mui/material'
import { CldImage as CloudinaryNextImage, CldImageProps } from 'next-cloudinary'
import NextImage from 'next/image'
import * as React from 'react'
import useStyles from './styles'

function breakpointImageWidth(width: number): number {
  const points = [
    200, 300, 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600,
  ]

  return points.find(p => p > width) ?? 1200
}

function ensureHttps(src: string): string {
  if (src.indexOf('//') === 0) {
    return `https:${src}`
  }
  return src
}

const Image = (
  props: Omit<CldImageProps, 'height' | 'width' | 'unoptimized' | 'alt'> & {
    alt?: string
    optimised?: boolean | undefined
    imageStyle?: React.CSSProperties
    width?: number | undefined
    height?: number | undefined
  }
): JSX.Element => {
  const {
    alt = '',
    width,
    height,
    optimised,
    imageStyle,
    src,
    ...restProps
  } = props
  const styles = useStyles({ width, height })

  const safeSrc = ensureHttps(props.src ?? '')

  const getImage = () => {
    if (props.src?.toLowerCase().includes('.svg') || !optimised) {
      return (
        <NextImage
          {...restProps}
          // Issue: https://github.com/vercel/next.js/discussions/26801
          // There's an issue when multiple Next Image are in a page, causing memory leak
          // And OOM to our pods. The quick fix was adding unoptimized prop for now.
          // Which will turn off the resizing feature of next image.
          // Once the vercel fixed this memory leak issue, we can just remove this prop.
          unoptimized
          width={0}
          height={0}
          src={safeSrc}
          className={styles.image}
          style={imageStyle}
          alt={alt}
        />
      )
    }

    return (
      // Cloudinary Next/Image
      <CloudinaryNextImage
        // Configurable props
        format="auto"
        quality={'auto' as `${number}`} // Obviously not a number, but type is incorrect as it should accept a string
        alt={alt}
        {...restProps}
        src={safeSrc}
        deliveryType="fetch"
        className={styles.image}
        style={imageStyle}
        // We're configuring the following to enforce our own size optimisation rather than rely on the next/image as
        // the images commonly appear blurred/pixelated if we set a fixed height and width.
        // We're also making a calculated assumption that for the majority of devices rendering these images they would
        // have a high PPI
        dpr="2.0"
        resize={
          width != null
            ? {
                crop: 'scale',
                width: breakpointImageWidth(width),
              }
            : undefined
        }
        // Providing a zero width and height to let NextJS know we don't need size optimisation as we rely on the
        // container to have the configured height and width in addition to the manual resize configuration above.
        // We then explicitly set these values to 100% of the container via CSS
        width={0}
        height={0}
      />
    )
  }

  return <Box className={styles.container}>{getImage()}</Box>
}

export default Image
