import { makeStyles } from '@mui/styles'
import { SPACING } from 'lib/theme'

// noinspection JSUnusedLocalSymbols
const useStyles = makeStyles(theme => ({
  wordWrap: {
    overflowWrap: 'break-word',
  },
  addPadding: {
    paddingLeft: theme.spacing(SPACING.M),
    paddingRight: theme.spacing(SPACING.M),
  },
  fullWidth: {
    width: '100%',
  },
}))

export default useStyles