import cx from 'classnames'
import React from 'react'
import Typography from '@mui/material/Typography'
import { getClasses } from './BaseCard'
import useGlobalStyles from 'styles/globalStyle'
import { ICardKicker } from 'lib/types/card'

export default function CardKicker({
  text,
  'data-test-id': testId,
  colour,
}: ICardKicker) {
  const classes = getClasses(colour)
  const globalClasses = useGlobalStyles()
  return (
    <Typography
      className={cx(classes.kicker, globalClasses.wordWrap)}
      data-test-id={testId}
      variant="subtitle2"
      component="p"
    >
      {text}
    </Typography>
  )
}
