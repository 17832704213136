import { useAuth0 } from '@auth0/auth0-react'
import { RedirectLoginOptions, User } from '@auth0/auth0-spa-js'
import Router from 'next/router'
import React, { ComponentType, FC, useEffect } from 'react'
import LoadingContainer from 'components/LoadingContainer/LoadingContainer'
import { clearCurrentUserAccount } from 'hooks/useCurrentUserAccount'
import useDefaultRoute from 'hooks/useDefaultRoute'
import { clearUserProfile } from 'hooks/useUserProfile'
import { pageviewWithFlush } from 'lib/gtm'

/**
 * @ignore
 */
const defaultOnRedirecting = (): JSX.Element => <></>

/**
 * @ignore
 */
const defaultReturnTo = (): string =>
  `${window.location.pathname}${window.location.search}`

/**
 * Options for the withAuthenticationRequired Higher Order Component
 */
export interface WithAuthenticationRequiredOptions {
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: '/profile'
   * })
   * ```
   *
   * or
   *
   * ```js
   * withAuthenticationRequired(Profile, {
   *   returnTo: () => window.location.hash.substr(1)
   * })
   * ```
   *
   * Add a path for the `onRedirectCallback` handler to return the user to after login.
   */
  returnTo?: string | (() => string)
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   onRedirecting: () => <div>Redirecting you to the login...</div>
   * })
   * ```
   *
   * Render a message to show that the user is being redirected to the login.
   */
  onRedirecting?: () => JSX.Element
  /**
   * ```js
   * withAuthenticationRequired(Profile, {
   *   loginOptions: {
   *     appState: {
   *       customProp: 'foo'
   *     }
   *   }
   * })
   * ```
   *
   * Pass additional login options, like extra `appState` to the login page.
   * This will be merged with the `returnTo` option used by the `onRedirectCallback` handler.
   */
  loginOptions?: RedirectLoginOptions
  /**
   * Check the user object for JWT claims and return a boolean indicating
   * whether or not they are authorized to view the component.
   */
  claimCheck?: (claims?: User) => boolean
}

/**
 * ```js
 * const MyProtectedComponent = withAuthenticationRequired(MyComponent);
 * ```
 *
 * When you wrap your components in this Higher Order Component and an anonymous user visits your component
 * they will be redirected to the login page and returned to the page they we're redirected from after login.
 */
export const withCustomAuth = <P extends object>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {}
): FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const {
      user,
      isAuthenticated,
      isLoading: isLoadingAuthUser,
      loginWithRedirect,
    } = useAuth0()
    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      claimCheck = (): boolean => true,
      loginOptions,
    } = options
    const defaultRoute = useDefaultRoute(user)

    /**
     * The page is accessible if the user has a valid auth state and there are no
     * JWT claim mismatches.
     */
    const hasValidClaim = claimCheck(user)
    const isAuthorisedToViewPage = isAuthenticated && hasValidClaim

    const AnyComponent = Component as any

    useEffect(() => {
      if (isLoadingAuthUser || isAuthorisedToViewPage) {
        return
      }

      if (isAuthenticated && !hasValidClaim) {
        // Since we know the user is authenticated but not authorised according to the claim check,
        // then we can skip redirecting to Auth0 and redirect straight to the users home page, depending
        // on their access levels
        Router.replace(defaultRoute)

        return
      }

      const opts = {
        ...loginOptions,
        appState: {
          ...(loginOptions && loginOptions.appState),
          returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
        },
      }

      const login = async () => {
        // This is Custom code added to track the pageview
        await pageviewWithFlush().then(_ => {
          clearUserProfile()
          clearCurrentUserAccount()
          return loginWithRedirect(opts)
        })
      }

      login()
    }, [
      isLoadingAuthUser,
      isAuthorisedToViewPage,
      loginWithRedirect,
      loginOptions,
      returnTo,
    ])

    if (isLoadingAuthUser) {
      return <LoadingContainer center />
    }

    return isAuthorisedToViewPage ? (
      <AnyComponent {...props} />
    ) : (
      onRedirecting()
    )
  }
}
