import React from 'react'
import MuiCard from '@mui/material/Card'
import Link from 'next/link'
import useStyles from './styles'
import { ICard } from 'lib/types/card'
import {
  PARTNER_URL_PREFIX,
  SCENTREGROUP_URL_DOMAIN,
  URL_REDIRECT_WHITELIST,
} from 'lib/config'
import { lightOrDark } from 'lib/utils'

// TODO: Attempted to extract out into styles.ts
// Had some type issues when applying useStyles to a const before exporting.
export function getClasses(
  colour?: string,
  minHeight?: number | string,
  maxHeight?: number | string
) {
  const colourScheme = lightOrDark(colour || '')
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles({ colourScheme, colour, minHeight, maxHeight })

  return classes
}

export default function BaseCard({
  children,
  'data-test-id': testId,
  cta,
  colour,
  minHeight,
  maxHeight,
  cardTabbable,
  handleClick = () => null,
}: ICard) {
  const classes = getClasses(colour, minHeight, maxHeight)

  const ctaUrl = (cta && cta.url) || ''

  const isInternalUrl = Boolean(
    (ctaUrl.includes(PARTNER_URL_PREFIX) &&
      ctaUrl.includes(SCENTREGROUP_URL_DOMAIN)) ||
      ctaUrl[0] === '/'
  )

  const isWhitelistedUrl = Boolean(
    URL_REDIRECT_WHITELIST.find(v => ctaUrl.includes(v))
  )

  return cta ? (
    <Link href={ctaUrl} legacyBehavior>
      <a
        {...(!isInternalUrl && { target: '_blank' })}
        {...(!cardTabbable && { tabIndex: -1 })}
        {...(!isWhitelistedUrl && { rel: 'noreferrer' })}
        className={classes.cardLink}
      >
        <MuiCard
          className={classes.rootLink}
          data-test-id={testId}
          component="article"
          onClick={handleClick}
        >
          {children}
        </MuiCard>
      </a>
    </Link>
  ) : (
    <MuiCard className={classes.root} data-test-id={testId} component="article">
      {children}
    </MuiCard>
  )
}
